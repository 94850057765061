<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton
                title="Client Status Report"
                btnTitle="Add new"
                :showBtn="false"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
                :isHideDate="true"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mr-top-1">
                    <label class="form-label">Select Bank</label>
                    <AsyncSelect
                        placeholder="Select Bank"
                        v-model="singleBank"
                        :api-service="fetchContactProfiles"
                        :additional-query="{ type: 'dealer'}"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg- mr-top-1">
                    <label class="form-label">Bank-Branch</label>
                    <AsyncSelect
                        v-model="singleDealer"
                        placeholder="Select Bank-Branch"
                        :api-service="fetchAllDealers"
                        :additional-query="dealerQuery"
                        :key="dealerRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Client: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>
                    <AsyncSelect
                        placeholder="Select Client"
                        v-model="contactProfile"
                        :api-service="fetchContactProfiles"
                        :additional-query="additionalContactOption"
                        :key="contactRenderKey"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mr-top-1">
                    <label class="form-label">Select BDO</label>
                    <AsyncSelect
                        placeholder="Select BDO"
                        v-model="singleSR"
                        :api-service="fetchAllSR"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="col-12">
            <div class="d-flex justify-content-end px-2 mb-2">
                <button class="btn btn-primary btn-block waves-effect waves-float waves-light" @click="exportReport">
                    Export
                </button>
            </div>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <ContactDropdownFilter 
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :companyRoles="companyRoles"
            :isFilterable="true"
        />

        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/crm/visit-plan/ClientStatusTable.vue';
import handleCrm from '@/services/modules/crm/crm.js'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import handleRole from "@/services/modules/role";
import handleContact from '@/services/modules/contact';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const tableItems = ref([]);
const params = reactive({
    searchTxt: '',
    offset: 10
});
const singleBank = ref(null);
const singleDealer = ref(null);
const contactProfile = ref(null)
const singleSR = ref(null);
const companyRoles = ref([]);
const dealerQuery = ref({
    sale_representative_id: '',
    contact_profile_id: ''
});
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: ''
});
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const { fetchClientVisitStatus, fetchAllDealers, fetchAllSR } = handleCrm();
const { fetchContactProfiles } = handleContact();
const { fetchCompanyDefaultRoles } = handleRole();

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const exportReport = () => {
    let token = localStorage.getItem('token');
    let url = `${process.env.VUE_APP_BASE_URL}/export/crm/client-status-export${getQuery()}&_token=${token}&is_export=1`;
    let a = document.createElement('a');
    a.setAttribute('href', url);
    a.click();
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${route.params.companyId}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

async function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    await getClientList();
}

const onClickGo = () => {
    getClientList()
}

const getClientList = async () => {
    const query = getQuery();
    loading.value = true

    await fetchClientVisitStatus(query).then(res => {
        loading.value = false
        if (!res.status){
            store.commit("resetPagination")
            return
        }
        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page
    if(params.searchTxt) query += `&search_txt=${params.searchTxt}`;
    if(singleBank.value) query += `&bank_id=${singleBank.value.id}`;
    if(singleDealer.value) query += `&dealer_id=${singleDealer.value.id}`;
    if(contactProfile.value) query += `&contact_profile_id=${contactProfile.value.id}`;
    if(singleSR.value) query += `&sale_representative_id=${singleSR.value.id}`;
    query += '&offset=' + params.offset
    return query;
}

watch(() => singleDealer.value, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue){
        additionalContactOption.value.dealer_id = newValue.id
    }
})

watch(() => singleBank.value, (newValue, oldValue) => {
    singleDealer.value = null;
    dealerQuery.value.contact_profile_id = '';
    dealerRenderKey.value += 1;

    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if(newValue) {
        dealerQuery.value.contact_profile_id = newValue.id;
    }

})

onMounted(async () => {
    await getClientList();
    await getCompanyRoles();
})
</script>

<style scoped>
.mr-top-1 {
    margin-top: 1% !important;
}
</style>