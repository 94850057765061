<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table visit-report-table">
                <thead>
                    <tr>
                        <th>Client ID</th>
                        <th>Clients name</th>
                        <th>Bank</th>
                        <th>Branch</th>
                        <th>BDO Name</th>
                        <th>Contact person Name</th>
                        <th>Contact number</th>
                        <th>Last Visit Date</th>
                        <th>Remarks / Last Status Update</th>
                        <th>Type</th>
                        <th>Agreement Value</th>
                        <th>Last Order No</th>
                        <th>Last Order Date</th>
                        <th>Last Invoice Date</th>
                        <th>Last Invoice No</th>
                        <th>Due Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">
                        <td>{{ item.serial_no }}</td>
                        <td>{{ item.full_name }}</td>
                        <td>{{ item.bank_name }}</td>
                        <td>{{ item.branch_name }}</td>
                        <td>{{ item.bdo_name }}</td>
                        <td>{{ item.contact_person_names }}</td>
                        <td>{{ item.contact_person_phones }}</td>
                        <td>{{ item.visit_date }}</td>
                        <td>{{ item.remarks }}</td>
                        <td>{{ item.sale_type }}</td>
                        <td>{{ item.agreement_amount }}</td>
                        <td>{{ item.order_number }}</td>
                        <td>{{ item.order_date }}</td>
                        <td>{{ item.invoice_date }}</td>
                        <td>{{ item.invoice_number }}</td>
                        <td>{{ item.due_amount }}</td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";

    const $route = useRoute();
    const $router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        }
    })

</script>

<style scoped>
.visit-report-table > :not(caption) > * > * {
    padding: 1rem !important;
}

.visit-report-table .pl-10px {
    padding-left: 10px !important;
}
</style>